<h2 mat-dialog-title>Edit Post</h2>

<mat-dialog-content>
  <div class="flex h-full" *ngIf="isLoading">
    <div class="m-auto">
      <mat-progress-spinner style="margin: 0 auto" mode="indeterminate"></mat-progress-spinner>
    </div>
  </div>

  <!-- reactions -->
  <ng-container *ngIf="!isLoading">
    <div class="flex flex-wrap items-center mb-2">
      <!-- Love -->
      <div
        class="
          flex
          items-center
          justify-center
          py-1
          px-3
          mr-3
          mb-3
          rounded-full
          leading-normal
          text-gray-500
          bg-gray-100
          dark:text-gray-300 dark:bg-gray-700
        "
      >
        <mat-icon [svgIcon]="'mat_outline:favorite_border'"></mat-icon>
        <span class="ml-2 text-lg font-medium whitespace-nowrap">Love:</span>
        <div class="ml-2 leading-6 text-lg font-medium whitespace-nowrap">{{ post.loveCount | nFormatter: 2 }}</div>
      </div>

      <!-- Like -->
      <div
        class="
          flex
          items-center
          justify-center
          py-1
          px-3
          mr-3
          mb-3
          rounded-full
          leading-normal
          text-gray-500
          bg-gray-100
          dark:text-gray-300 dark:bg-gray-700
        "
      >
        <mat-icon [svgIcon]="'heroicons_outline:thumb-up'" class="ml-2"></mat-icon>
        <span class="ml-2 text-lg font-medium whitespace-nowrap">Like:</span>
        <div class="ml-2 leading-6 text-lg font-medium whitespace-nowrap">{{ post.likeCount | nFormatter: 2 }}</div>
      </div>

      <!-- Regret -->
      <div
        class="
          flex
          items-center
          justify-center
          py-1
          px-3
          mr-3
          mb-3
          rounded-full
          leading-normal
          text-gray-500
          bg-gray-100
          dark:text-gray-300 dark:bg-gray-700
        "
      >
        <mat-icon [svgIcon]="'heroicons_outline:emoji-sad'"></mat-icon>
        <span class="ml-2 text-lg font-medium whitespace-nowrap">Regret:</span>
        <div class="ml-2 leading-6 text-lg font-medium whitespace-nowrap">{{ post.regretCount | nFormatter: 2 }}</div>
      </div>

      <!-- Love -->
      <div
        class="
          flex
          items-center
          justify-center
          py-1
          px-3
          mr-3
          mb-3
          rounded-full
          leading-normal
          text-gray-500
          bg-gray-100
          dark:text-gray-300 dark:bg-gray-700
        "
      >
        <mat-icon [svgIcon]="'heroicons_outline:thumb-up'" class="ml-2"></mat-icon>
        <span class="ml-2 text-lg font-medium whitespace-nowrap">Appr.:</span>
        <div class="ml-2 leading-6 text-lg font-medium whitespace-nowrap">
          {{ post.appreciationCount | nFormatter: 2 }}
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Alert -->
  <fuse-alert
    *ngIf="showAlert"
    [appearance]="'outline'"
    [showIcon]="false"
    [type]="alert.type"
    [@shake]="alert.type === 'error'"
    [dismissible]="true"
    [dismissed]="false"
  >
    {{ alert.message }}
  </fuse-alert>

  <form [formGroup]="form" #dataForm="ngForm" (ngSubmit)="save()" *ngIf="!isLoading">
    <div class="flex flex-col">
      <!-- ID & city -->
      <div class="flex flex-col md:flex-row gap-2">
        <mat-form-field class="w-full flex-auto md:w-1/2" [floatLabel]="'always'">
          <mat-label>ID</mat-label>
          <input matInput disabled [value]="post._id" />
        </mat-form-field>
        <!-- City -->
        <mat-form-field class="w-full flex-auto md:w-1/2" [floatLabel]="'always'" disabled>
          <mat-label>City</mat-label>
          <input matInput disabled [value]="post.city?.name" />
        </mat-form-field>
      </div>

      <!-- Dish name & Restaurant -->
      <div class="flex flex-col md:flex-row">
        <mat-form-field class="w-full flex-auto md:w-1/2" [floatLabel]="'always'">
          <mat-label>Dish Name</mat-label>
          <input matInput placeholder="Enter Dish name" formControlName="dishName" required />
          <mat-error *ngIf="form.get('dishName').hasError('required')"> dishName is required </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full flex-auto md:w-1/2 md:pl-2" [floatLabel]="'always'">
          <mat-label>Restaurant *</mat-label>
          <mat-select [formControlName]="'restaurant'" placeholder="Restaurant">
            <!-- place ngx-mat-select-search inside mat-option to allow opening without initial selection -->
            <mat-option>
              <ngx-mat-select-search [formControl]="restaurantServerSideFilteringCtrl" [searching]="searching">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let restaurant of filteredServerSideRestaurants | async" [value]="restaurant?._id">
              {{ restaurant?.name }}
            </mat-option>
          </mat-select>

          <mat-error *ngIf="form.get('restaurant').hasError('required')"> restaurant is required </mat-error>
        </mat-form-field>
      </div>

      <!-- Category & cuisine -->
      <div class="flex flex-col md:flex-row">
        <mat-form-field class="w-full flex-auto md:w-1/2" [floatLabel]="'always'">
          <mat-label>Category *</mat-label>
          <mat-select [formControlName]="'parentCategory'" placeholder="Select Category">
            <mat-option *ngFor="let parentCategory of categories" [value]="parentCategory._id">
              {{ parentCategory.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('parentCategory').hasError('required')"> Category is required </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full flex-auto md:w-1/2 md:pl-2" [floatLabel]="'always'">
          <mat-label>Cuisine</mat-label>
          <input matInput disabled [value]="post.cuisine?.name" />
        </mat-form-field>
      </div>

      <!-- Foodcontentinfo & Foodcontentinfosubcategories -->
      <div class="flex flex-col md:flex-row">
        <mat-form-field class="w-full flex-auto md:w-1/2" [floatLabel]="'always'">
          <mat-label>Food Content Info</mat-label>
          <mat-select [formControlName]="'foodContentInfo'" placeholder="Select foodContentInfo">
            <mat-option *ngFor="let fci of foodContentInfo" [value]="fci._id">
              {{ fci.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('parentCategory').hasError('required')"> FoodContentInfo is required </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full flex-auto md:w-1/2 md:pl-2" [floatLabel]="'always'">
          <mat-label>Food Content Info Sub Category</mat-label>
          <mat-select [formControlName]="'foodContentInfoSubcategory'" placeholder="Select sub category" multiple>
            <mat-option *ngFor="let fcisub of fciSubcategory" [value]="fcisub._id">
              {{ fcisub.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('foodContentInfoSubcategory').hasError('required')">
            FCI sub category is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="flex flex-col md:flex-row">
        <mat-form-field class="w-full md:w-1/2" [floatLabel]="'always'">
          <mat-label>Price</mat-label>
          <input matInput placeholder="Enter price" formControlName="price" type="number" />
          <mat-error *ngIf="form.get('price').hasError('pattern')"> Only number is allowed </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full flex-auto md:w-1/2 md:pl-2" [floatLabel]="'always'">
          <mat-label>Food Allergies</mat-label>
          <mat-select [formControlName]="'foodAllergies'" placeholder="Select allergies" multiple>
            <mat-option *ngFor="let fa of foodAllergies" [value]="fa._id">
              {{ fa.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Image -->
      <div class="flex flex-auto items-center">
        <div
          class="relative flex items-center justify-center w-100 h-100 box-border overflow-hidden ring-4 ring-bg-card"
        >
          <!-- Upload / Remove image -->
          <div class="absolute inset-0 bg-black bg-opacity-50 z-10" *ngIf="showImageBg"></div>
          <div class="absolute inset-0 flex items-center justify-center z-20">
            <div>
              <input
                id="avatar-file-input"
                class="absolute h-0 w-0 opacity-0 invisible pointer-events-none"
                type="file"
                [multiple]="false"
                [accept]="'image/jpeg, image/png'"
                (change)="uploadImage(imageFileInput.files)"
                #imageFileInput
                [disabled]="form.disabled"
              />
              <label
                class="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer hover:bg-purple-600"
                for="avatar-file-input"
                matRipple
              >
                <mat-icon class="text-white" [svgIcon]="'heroicons_outline:camera'"></mat-icon>
              </label>
            </div>
            <div>
              <button
                mat-icon-button
                (click)="removeImage()"
                *ngIf="image"
                class="hover:bg-purple-600"
                [disabled]="form.disabled"
              >
                <mat-icon class="text-white" [svgIcon]="'heroicons_outline:trash'"></mat-icon>
              </button>
            </div>
          </div>
          <!-- Image/Letter -->
          <img
            class="object-cover w-full h-full"
            *ngIf="image"
            [src]="image"
            alt="post image"
            (error)="showImageBg = true"
          />
          <!-- <div class="flex items-center justify-center w-full h-full rounded overflow-hidden uppercase text-8xl font-bold leading-none bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200"
                        *ngIf="!contact.avatar">
                        {{contact.name.charAt(0)}}
                    </div> -->
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button (click)="close()">Close</button>

  <button
    class="mat-raised-button"
    mat-button
    [color]="'primary'"
    [disabled]="form.disabled"
    (click)="save()"
    *ngIf="!isLoading"
  >
    <span *ngIf="!form.disabled"> Save </span>
    <mat-progress-spinner *ngIf="form.disabled" [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
  </button>
</mat-dialog-actions>
