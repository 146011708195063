import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { Game, GameInputDTO, PopulatedGame } from '../models';

@Injectable({
  providedIn: 'root',
})
export class GamesService {
  private _gameList: BehaviorSubject<Game[]> = new BehaviorSubject([]);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get gameList$(): Observable<Game[]> {
    return this._gameList.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getGames(): Observable<Game[]> {
    return this._httpClient.get<Game[]>(environment.apiURL + 'game/all').pipe(
      tap((response) => {
        this._gameList.next(response);
      })
    );
  }

  create(data: GameInputDTO): Observable<{ message: string; game: Game }> {
    return this.gameList$.pipe(
      take(1),
      switchMap((games) =>
        this._httpClient.post<{ message: string; game: Game }>(environment.apiURL + 'game/create', data).pipe(
          tap((response) => {
            this._gameList.next([response.game, ...games]);
          })
        )
      )
    );
  }

  update(data: Game, id: string): Observable<{ message: string; game: Game }> {
    return this.gameList$.pipe(
      take(1),
      switchMap((games) =>
        this._httpClient.put<{ message: string; game: Game }>(environment.apiURL + 'game/update/' + id, data).pipe(
          tap((updateData) => {
            const index = games.findIndex((item) => item._id === id);

            if (index !== -1) {
              games[index] = updateData.game;

              this._gameList.next(games);
            }
          })
        )
      )
    );
  }

  addWinners(
    data: { winners: Game['winners']; isNotifyAll?: boolean },
    id: string
  ): Observable<{ message: string; game: Game }> {
    return this.gameList$.pipe(
      take(1),
      switchMap((games) =>
        this._httpClient.post<{ message: string; game: Game }>(environment.apiURL + 'game/addWinners/' + id, data).pipe(
          tap((updateData) => {
            const index = games.findIndex((item) => item._id === id);

            if (index !== -1) {
              games[index] = updateData.game;

              this._gameList.next(games);
            }
          })
        )
      )
    );
  }

  details(gameId: string): Observable<PopulatedGame> {
    return this._httpClient.get<PopulatedGame>(environment.apiURL + 'game/details/' + gameId);
  }

  enableDisable(data: {
    gameId: string;
    isRefundGoodies?: boolean;
  }): Observable<{ isActive: boolean; message: string }> {
    return this.gameList$.pipe(
      take(1),
      switchMap((allRecords) =>
        this._httpClient
          .patch<{ isActive: boolean; message: string }>(environment.apiURL + 'game/enableDisable', data)
          .pipe(
            tap((updateData) => {
              const index = allRecords.findIndex((item) => item._id === data.gameId);

              allRecords[index].isActive = updateData.isActive;

              this._gameList.next(allRecords);
            })
          )
      )
    );
  }
}
