/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'mat_outline:dashboard',
    link: '/dashboard',
  },
  {
    id: 'countryCityStates',
    title: 'Country City States',
    type: 'basic',
    icon: 'mat_outline:outlined_flag',
    link: '/countrycitystates',
  },
  {
    id: 'users',
    title: 'Users',
    type: 'basic',
    icon: 'heroicons_outline:users',
    link: '/users',
  },
  {
    id: 'foodcontentinfo',
    title: 'Food Content Info',
    type: 'basic',
    icon: 'mat_outline:category',
    link: '/foodcontentinfo',
  },
  {
    id: 'category',
    title: 'Category',
    type: 'basic',
    icon: 'mat_outline:category',
    link: '/category',
  },
  {
    id: 'restaurant-ratings-category',
    title: 'Rest. Ratings Category',
    type: 'basic',
    icon: 'mat_outline:category',
    link: '/restaurant-ratings-categories',
  },
  {
    id: 'restaurant',
    title: 'Restaurants',
    type: 'basic',
    icon: 'mat_outline:restaurant',
    link: '/restaurant',
  },
  {
    id: 'cuisine',
    title: 'Cuisines',
    type: 'basic',
    icon: 'cuisine',
    link: '/cuisine',
  },
  {
    id: 'posts',
    title: 'Posts',
    type: 'basic',
    icon: 'heroicons_outline:rss',
    link: '/posts',
  },
  {
    id: 'claimRestaurants',
    title: 'Restaurant Claims',
    type: 'basic',
    icon: 'mat_outline:attach_money',
    link: '/claimRestaurants',
  },
  {
    id: 'claimedRewards',
    title: 'Goodies Claimed',
    type: 'basic',
    icon: 'heroicons_outline:gift',
    link: '/claimedRewards',
  },
  {
    id: 'helps',
    title: 'Helps',
    type: 'basic',
    icon: 'heroicons_outline:question-mark-circle',
    link: '/helps',
  },
  {
    id: 'postReports',
    title: 'Reports - Post',
    type: 'basic',
    icon: 'mat_outline:report_gmailerrorred',
    link: '/postReports',
  },
  {
    id: 'restaurantReports',
    title: 'Reports - Restaurants',
    type: 'basic',
    icon: 'mat_outline:report_gmailerrorred',
    link: '/restaurantReports',
  },
  {
    id: 'an',
    title: 'Automatic Notifications',
    type: 'basic',
    icon: 'heroicons_outline:bell',
    link: '/auto-notification',
  },
  {
    id: 'foodallergies',
    title: 'Food Allergies',
    type: 'basic',
    icon: 'mat_outline:coronavirus',
    link: '/foodallergies',
  },
  {
    id: 'prh',
    title: 'Post reaction hashtags',
    type: 'basic',
    icon: 'heroicons_outline:hashtag',
    link: '/post-reaction-hashtags',
  },
  {
    id: 'psd',
    title: 'Post searches',
    type: 'basic',
    icon: 'heroicons_outline:document-search',
    link: '/postSearches',
  },
  {
    id: 'fmc',
    title: 'Foodophile Codes',
    type: 'basic',
    icon: 'heroicons_outline:qrcode',
    link: '/fmc',
  },
  {
    id: 'games',
    title: 'Games',
    type: 'basic',
    icon: 'mat_outline:videogame_asset',
    link: '/games',
  },
  {
    id: 'config',
    title: 'Configurations',
    subtitle: 'Configurations for app',
    type: 'group',
    icon: 'heroicons_outline:home',
    children: [
      {
        id: 'config.goodies',
        title: 'Goodies',
        type: 'basic',
        icon: 'heroicons_outline:gift',
        link: '/config/goodies',
      },
      {
        id: 'config.blacklistwords',
        title: 'Blacklist Words',
        type: 'basic',
        icon: 'mat_outline:block',
        link: '/config/blacklistwords',
      },
      {
        id: 'config.rewards',
        title: 'Rewards',
        type: 'basic',
        icon: 'mat_outline:emoji_events',
        link: '/config/rewards',
      },
      {
        id: 'config.rewardcombos',
        title: 'Reward Combos',
        type: 'basic',
        icon: 'mat_outline:emoji_events',
        link: '/config/rewardcombos',
      },
      {
        id: 'config.reportreasons',
        title: 'Report Reasons - Posts',
        type: 'basic',
        icon: 'mat_outline:report',
        link: '/config/reportreasons',
      },
      {
        id: 'config.restaurantreportreasons',
        title: 'Report Reasons - Restaurant',
        type: 'basic',
        icon: 'mat_outline:report',
        link: '/config/restaurantReportreasons',
      },
      {
        id: 'config.settings',
        title: 'Settings',
        type: 'basic',
        icon: 'heroicons_outline:cog',
        link: '/config/settings',
      },
      {
        id: 'config.updater',
        title: 'Updater',
        type: 'basic',
        icon: 'mat_outline:update',
        link: '/config/updater',
      },
    ],
  },
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
